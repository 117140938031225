import React, { useCallback, useState } from 'react';

import Slider from 'react-slick';
import {
  Container,
  BannerMain,
  Enjoy,
  Choose,
  Master,
  Skills,
  Stories,
  Stacking,
  Learn,
  Global,
} from './styles';
import bannerMain from '~/assets/banners/banner-home.png';
import bannerMainMob from '~/assets/banners/banner-home-mob.png';
import bannerEnjoy from '~/assets/banners/banner-enjoy.png';
import bannerEnjoyMob from '~/assets/banners/banner-enjoy-mob.png';
import bannerStacking from '~/assets/banners/banner-stacking.png';
import bannerStackingMob from '~/assets/banners/banner-stacking-mob.png';
import bannerMaster from '~/assets/banners/banner-master.png';
import squareEnjoy from '~/assets/defaults/square-enjoy.svg';
import squareTri from '~/assets/defaults/square-triangle.svg';
import cube from '~/assets/defaults/cube-choose.png';
import cubeLearn from '~/assets/defaults/cube-learn.png';
import bgYoutube from '~/assets/defaults/banner-youtube.png';
import bgFacebook from '~/assets/defaults/banner-facebook.png';
import bgEcommmerce from '~/assets/defaults/banner-ecommerce.png';
import quoteStories from '~/assets/icons/quote-stories.png';
import bgYouAds from '~/assets/defaults/banner-ads.png';
import avatar1 from '~/assets/defaults/avatar1.png';
import avatar2 from '~/assets/defaults/avatar2.png';
import avatar3 from '~/assets/defaults/avatar3.png';
import avatar4 from '~/assets/defaults/avatar4.png';

import avatar5 from '~/assets/defaults/avatar5.png';
import avatar6 from '~/assets/defaults/avatar6.png';
import avatar7 from '~/assets/defaults/avatar7.png';
import avatar8 from '~/assets/defaults/avatar8.png';

import avatar9 from '~/assets/defaults/avatar9.png';
import avatar10 from '~/assets/defaults/avatar10.png';
import avatar11 from '~/assets/defaults/avatar11.png';
import avatar12 from '~/assets/defaults/avatar12.png';

import avatar13 from '~/assets/defaults/avatar13.png';
import avatar14 from '~/assets/defaults/avatar14.png';
import avatar15 from '~/assets/defaults/avatar15.png';
import avatar16 from '~/assets/defaults/avatar16.png';

import person1 from '~/assets/defaults/person-1-stories.png';
import person2 from '~/assets/defaults/person-2-stories.png';
import person3 from '~/assets/defaults/person-3-stories.png';
import person4 from '~/assets/defaults/person-4-stories.png';
import person5 from '~/assets/defaults/person-5-stories.png';
import person6 from '~/assets/defaults/person-6-stories.png';
import star from '~/assets/icons/star.svg';
import stars from '~/assets/icons/stars.svg';
import discoverMob1 from '~/assets/defaults/discover1-mob.png';
import discoverMob2 from '~/assets/defaults/discover2-mob.png';
import discoverMob3 from '~/assets/defaults/discover3-mob.png';
import discoverMob4 from '~/assets/defaults/discover4-mob.png';
import discover1 from '~/assets/defaults/discover1.png';
import discover2 from '~/assets/defaults/discover2.png';
import discover3 from '~/assets/defaults/discover3.png';
import discover4 from '~/assets/defaults/discover4.png';
import discover5 from '~/assets/defaults/discover5.png';
import discover6 from '~/assets/defaults/discover6.png';
import line from '~/assets/defaults/line-home.png';
import check from '~/assets/defaults/check-home.svg';
import checkWhite from '~/assets/defaults/check-home-white.svg';
import saveDesktop from '~/assets/defaults/save-desk.png';
import saveMobile from '~/assets/defaults/save-mob.png';
import ModalBuyPlan from '~/components/ModalBuyPlan';

const Home: React.FC = () => {
  const [showBuyPlan, setShowBuyPlan] = useState(false);
  const [planSelected, setPlanSelected] = useState('launchpad');
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleBuyPlan = useCallback((planSel) => {
    setShowBuyPlan(true);
    setPlanSelected(planSel);
  }, []);
  return (
    <Container>
      <BannerMain className="container-fluid container-xxl pb-5">
        <div className="row justify-content-center">
          <div className="col-12 px-lg-5">
            <img
              src={bannerMain}
              alt="Banner"
              className="d-none d-sm-block w-100 "
            />
            <img src={bannerMainMob} alt="Banner" className="w-100 d-sm-none" />
          </div>
          <div className="col-lg-10 text-center">
            <h1 className="text-center pt-4">
              Master Transformative Skills to{' '}
              <span>Build Your Freedom Business</span>
            </h1>
            <h2 className="text-center px-xl-4 pt-3">
              <span className="fw-semibold">
                Unlock Your Entrepreneurial Potential
              </span>{' '}
              with Premium and Exclusive Resources <br className="d-sm-none" />
              <span className="fs-span position-relative">
                For&nbsp;
                <img src={line} alt="Line" className="d-lg-none" />
                <span className="font-weight-bold position-relative d-inline">
                  Just&nbsp;$7
                  <img src={line} alt="Line" className="d-none d-lg-block" />
                </span>
              </span>
            </h2>
            <button
              onClick={() => handleBuyPlan('freedom')}
              type="button"
              className="btn-access my-4 d-lg-none mt-5"
            >
              <span role="img" aria-label="flowers">
                🎉
              </span>{' '}
              Get Early Access
            </button>
            <h3 className="px-3 my-5 my-lg-3">
              At Wiserr, we fuel your entrepreneurial journey with courses that
              <span>go beyond mere learning.</span> Our programs are{' '}
              <span>designed to ignite your potential, foster innovation,</span>{' '}
              and help you <span>build a thriving business future.</span>
            </h3>
            <button
              onClick={() => handleBuyPlan('freedom')}
              type="button"
              className="btn-access my-4 d-none d-lg-block mx-auto mt-5"
            >
              <span role="img" aria-label="flowers">
                🎉
              </span>{' '}
              Get Early Access
            </button>
          </div>
          <div className="col-lg-10 px-lg-0 mt-lg-3 pt-lg-5">
            <div className="box overflow-auto">
              <div className="min-width d-flex align-items-center">
                <div className="text-center">
                  <span className="number d-block">52+</span>
                  <span className="d-block">Courses</span>
                </div>
                <div className="hash" />
                <div className="text-center">
                  <span className="number d-block">375+</span>
                  <span className="d-block">Lessons</span>
                </div>
                <div className="hash" />
                <div className="text-center">
                  <span className="number d-block">3,100+</span>
                  <span className="d-block">Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BannerMain>
      <Enjoy>
        <div className="circle" />
        <div className="bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="text-center mb-4 mt-lg-5 pt-lg-3">
                  Flexible Learning Formats Tailored for Entrepreneurs
                </h2>
              </div>
              <div className="col-lg-10 text-center">
                <h3 className="text-center mb-5">
                  Explore video, audio, and live sessions crafted to match your
                  entrepreneurial pace. Master new skills perfect for on-the-go
                  insights and interactive growth.{' '}
                  <span className="fw-semibold">
                    Our formats seamlessly align with your business needs,
                    ensuring efficient learning and growth.
                  </span>
                </h3>
              </div>
              <div className="col-lg-10 mt-4 px-sm-4 position-relative">
                <img src={squareEnjoy} alt="Square" className="square-enjoy" />
                <img
                  src={bannerEnjoy}
                  alt="Banner Enjoy"
                  className="d-none d-sm-block enjoy-img mt-5 w-100"
                />
                <img
                  src={bannerEnjoyMob}
                  alt="Banner Enjoy"
                  className="d-sm-none enjoy-img mt-5 w-100"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="triangle" />
      </Enjoy>
      <Choose id="why">
        <div className="container-fluid container-xxl pb-5">
          <div className="row justify-content-center">
            <div className="col-12 mt-4 mb-5 mb-lg-4">
              <h2 className="position-relative text-center mb-3">
                <img
                  src={squareTri}
                  alt="Square"
                  className="d-none d-sm-block square-tri"
                />
                <span className="d-block">WHY</span>Choose Wiserr
                <img src={cube} alt="Cube" className="choose-cube" />
              </h2>
              <p className="mb-5 mb-lg-2">
                Empowering Your Entrepreneurial Success
              </p>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number one">1</div>
                <h2 className="mt-3">
                  Learn Anywhere, <br />
                  Anytime
                </h2>
                <h3>
                  Courses available on all devices, for learning that fits your
                  life.
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-5">
                <div className="number two">2</div>
                <h2 className="mt-3">
                  Practical, Relevant <br />
                  Learning
                </h2>
                <h3>Strategically designed for real-world business impact.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number three">3</div>
                <h2 className="mt-3">
                  Always-On
                  <br />
                  Support
                </h2>
                <h3>We are always there for you anytime you need help.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-n5">
                <div className="number four">4</div>
                <h2 className="mt-3">
                  Diverse Learning
                  <br />
                  Experiences
                </h2>
                <h3>
                  Explore from video and audio to text — customized to your
                  preferences.
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number five">5</div>
                <h2 className="mt-3">
                  Recognized
                  <br />
                  Achievements
                </h2>
                <h3>
                  Earn certificates that validate your learning and enhance your
                  credibility.
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-n5">
                <div className="number six">6</div>
                <h2 className="mt-3">
                  Automation
                  <br />
                  Tools
                </h2>
                <h3>
                  Boost conversions and growth with our Webinar and Blogging
                  tools.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Choose>
      <Master>
        <div className="container-fluid container-xxl">
          <div className="row">
            <div className="col-lg-4 pr-lg-0 text-center text-lg-left bg-polygon-mob">
              <h2 className="my-4 mt-lg-5 mb-4">
                Master skills with in-depth learning
              </h2>
              <h3 className="mb-0">
                Discover a new learning experience, carefully tailored for
                entrepreneurial success. Access diverse courses designed to
                deepen your expertise and propel your business forward.
              </h3>
              <button
                onClick={() => handleBuyPlan('freedom')}
                type="button"
                className="btn-access mb-5 mb-lg-4 mt-5"
              >
                <span role="img" aria-label="flowers">
                  🎉
                </span>{' '}
                Get Early Access
              </button>
            </div>
            <div className="col-lg-8 pl-lg-5">
              <img src={bannerMaster} alt="Master Banner" />
            </div>
          </div>
        </div>
      </Master>
      <Skills id="courses">
        <div className="invert-circle" />
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-lg-10 position-relative">
              <img src={stars} alt="Stars" className="stars1" />
              <img src={star} alt="Star" className="star1" />
              <h2 className="mt-4 mb-5">
                Improve Your Skills, Better Your Life.
                <br />
                <span>Anytime, anywhere.</span>
              </h2>
              <div className="row justify-content-center">
                <div className="col-lg-10 d-flex flex-wrap justify-content-center justify-content-lg-around">
                  <button type="button" className="skills">
                    Online Marketing
                  </button>
                  <button type="button" className="skills">
                    Facebook Ads
                  </button>
                  <button type="button" className="skills">
                    Youtube Ads
                  </button>
                  <button type="button" className="skills">
                    eCommerce
                  </button>
                  <button type="button" className="skills">
                    Marketing
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 px-lg-5 d-none d-lg-block">
              <h3 className="mt-5 pt-3">
                Master key entrepreneurial skills needed to succeed in today's
                marketplace. Our curated courses, from foundational skills to
                advanced strategies, are designed to transform your vision into
                reality. Embrace a learning experience that opens doors to new
                opportunities and fuels entrepreneurial growth.
              </h3>
            </div>
            <div className="w-100 overflow-auto bar-scroll">
              <div className="max-w-skills d-flex mt-4 pt-2">
                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgYoutube}
                      alt="Banner youtube"
                      className="w-100 bg-card"
                    />
                    <div className="title">YouTube Workshop</div>
                    <div className="retangle" />
                  </div>
                  <h4 className="mt-4 pt-3">
                    Learn the step-by-step process to create your Youtube
                    channel
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar1} alt="avatar" />
                    <img src={avatar2} alt="avatar" />
                    <img src={avatar3} alt="avatar" />
                    <img src={avatar4} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+156 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>
                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgFacebook}
                      alt="Banner facebook"
                      className="w-100 bg-card"
                    />
                    <div className="title">Facebook Ads</div>
                    <div className="retangle" />
                  </div>
                  <h4 className="mt-4 pt-3">
                    Everything you need to know to run successful facebook ads
                    campaigns
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar5} alt="avatar" />
                    <img src={avatar6} alt="avatar" />
                    <img src={avatar7} alt="avatar" />
                    <img src={avatar8} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+228 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>

                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgEcommmerce}
                      alt="Banner Ecommerce"
                      className="w-100 bg-card"
                    />
                    <div className="title">Ecom Mastery</div>
                    <div className="retangle" />
                  </div>
                  <h4 className="mt-4 pt-3">
                    Learn how to launch and scale your own e-commerce store
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar9} alt="avatar" />
                    <img src={avatar10} alt="avatar" />
                    <img src={avatar11} alt="avatar" />
                    <img src={avatar12} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+97 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>

                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgYouAds}
                      alt="Banner youtube Ads"
                      className="w-100 bg-card"
                    />
                    <div className="title">YouTube Ads</div>
                    <div className="retangle" />
                  </div>
                  <h4 className="mt-4 pt-3">
                    Discover how you can launch successful youtube ads campaigns
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar13} alt="avatar" />
                    <img src={avatar14} alt="avatar" />
                    <img src={avatar15} alt="avatar" />
                    <img src={avatar16} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+277 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-5 pb-5">
              <div className="d-inline-block position-relative">
                <button
                  onClick={() => handleBuyPlan('freedom')}
                  type="button"
                  className="btn-access my-4"
                >
                  <span role="img" aria-label="flowers">
                    🎉
                  </span>{' '}
                  Get Early Access
                </button>
                <img src={squareEnjoy} alt="Square" className="square-skills" />
                <img src={stars} alt="Stars" className="stars2" />
                <img src={star} alt="Star" className="star2" />
                <img src={star} alt="Star" className="star3" />
              </div>
            </div>
          </div>
        </div>
      </Skills>
      <Stories id="stories">
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-lg-9 mt-5 pt-5 pb-5 pb-lg-0">
              <h3 className="mb-3 mt-5 pt-lg-5">MEMBER STORIES</h3>
              <h2>
                What our members think
                <br className="d-none d-sm-block" />
                about with us
              </h2>
            </div>
            <div className="col-lg-6 px-0 px-sm-3 my-5 py-5 position-relative">
              <img src={person1} alt="Person" className="person1" />
              <img src={person2} alt="Person" className="person2" />
              <img src={person3} alt="Person" className="person3" />
              <img src={person4} alt="Person" className="person4" />
              <img src={person5} alt="Person" className="person5" />
              <img src={person6} alt="Person" className="person6" />
              <Slider {...settings}>
                <div>
                  <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                    <div className="px-4">
                      <img
                        src={quoteStories}
                        alt="Quote"
                        className="quote-stories"
                      />
                    </div>
                    <h4 className="my-4 px-4">
                      Thanks to Wiserr, we've revolutionized our digital
                      marketing, converting online browsers into committed
                      customers quickly. It's a game-changer - you can do it too
                    </h4>
                    <h5 className="mb-0">BRAT PALMER</h5>
                    <h6 className="mb-3">Senior Brand Designer</h6>
                  </div>
                  <div className="bg-transparent-1" />
                  <div className="bg-transparent-2" />
                </div>
                <div>
                  <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                    <div className="px-4">
                      <img
                        src={quoteStories}
                        alt="Quote"
                        className="quote-stories"
                      />
                    </div>
                    <h4 className="my-4 px-4">
                      Wiserr has transformed my approach to learning. The
                      practical courses have given me skills I apply daily in my
                      work.
                    </h4>
                    <h5 className="mb-0">JAMES NGUYEN</h5>
                    <h6 className="mb-3">San Francisco, USA</h6>
                  </div>
                  <div className="bg-transparent-1" />
                  <div className="bg-transparent-2" />
                </div>
                <div>
                  <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                    <div className="px-4">
                      <img
                        src={quoteStories}
                        alt="Quote"
                        className="quote-stories"
                      />
                    </div>
                    <h4 className="my-4 px-4">
                      Wiserr's flexible learning options fits great with my
                      schedule. Their engaging courses, especially the Spanish
                      ones, are invaluable!
                    </h4>
                    <h5 className="mb-0">LUCIA PEREZ</h5>
                    <h6 className="mb-3">Madrid, Spain</h6>
                  </div>
                  <div className="bg-transparent-1" />
                  <div className="bg-transparent-2" />
                </div>
                <div>
                  <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                    <div className="px-4">
                      <img
                        src={quoteStories}
                        alt="Quote"
                        className="quote-stories"
                      />
                    </div>
                    <h4 className="my-4 px-4">
                      The supportive community and superior content have fueled
                      my career goals and aspirations. Highly recommend!
                    </h4>
                    <h5 className="mb-0">ABIGAIL MWANGI</h5>
                    <h6 className="mb-3">Nairobi, Kena</h6>
                  </div>
                  <div className="bg-transparent-1" />
                  <div className="bg-transparent-2" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="circle" />
      </Stories>
      <Stacking>
        <div className="container-fluid container-xxl">
          <div className="row">
            <div className="col-12 mt-lg-5 mb-5">
              <h2 className="mb-5 mb-lg-2">
                <span>Acquiring new skills</span>
                <br />
                has never been easier
              </h2>
            </div>
            <div className="col-lg-8 px-4 position-relative">
              <img src={squareEnjoy} alt="Square" className="square-stacking" />
              <img
                src={bannerStacking}
                alt="Banner Stacking"
                className="d-none d-sm-block stacking-img w-100"
              />
              <img
                src={bannerStackingMob}
                alt="Banner Stacking"
                className="d-sm-none stacking-img w-100"
              />
            </div>
            <div className="col-lg-4 pl-lg-4 d-flex flex-column justify-content-center">
              <h3 className="mt-5 mt-lg-0 mb-5 text-center text-lg-right pl-lg-4">
                Give yourself an{' '}
                <span>
                  unfair <b>advantage</b>
                </span>
              </h3>
              <h4 className="mb-5 text-center text-lg-right">
                <span>
                  Skills are the only assets that will always appreciate in
                  time.
                </span>{' '}
                Stacking skills that complement each other will ways protect you
                from whatever comes your way in business and your career.
              </h4>
              <div className="d-flex mx-auto mr-lg-0 ml-lg-auto">
                <button
                  onClick={() => handleBuyPlan('freedom')}
                  type="button"
                  className="ml-lg-auto btn-access my-4"
                >
                  <span role="img" aria-label="flowers">
                    🎉
                  </span>{' '}
                  Get Early Access
                </button>
              </div>
            </div>
          </div>
        </div>
      </Stacking>
      <Learn>
        <div className="container-fluid container-xxl">
          <div className="row overflow-hidden justify-content-center mt-5 pt-5">
            <div className="col-lg-11 mt-lg-5 pt-lg-5 position-relative">
              <h2>
                There Is No Better Place
                <br />
                <span>To Learn</span>
              </h2>
              <img
                src={squareTri}
                alt="Square"
                className="square-tri d-none d-lg-block"
              />
            </div>
            <div className="col-lg-5">
              <h3>Simple pricing for everyone</h3>
              <p className="mg-bottom">
                We design learning experiences that fit in your pocket and help
                you grow your bottom line.
              </p>
            </div>
          </div>
          <div className="row d-sm-none">
            <div className="col-12">
              <div className="px-5 pt-5 bg-white prices-mob">
                <div>
                  <img src={saveMobile} alt="Save" className="save" />
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Launchpad</h2>
                  <h3>$7</h3>
                  <p className="mb-5">One-time</p>
                  <button
                    onClick={() => handleBuyPlan('launchpad')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Get Started
                  </button>
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Growth</h2>
                  <h3>$97</h3>
                  <p className="mb-5">Monthly Subscription</p>
                  <button
                    onClick={() => handleBuyPlan('growth')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Get Started
                  </button>
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Accelerator</h2>
                  <h3>$897</h3>
                  <p className="mb-5">Yearly Subscription</p>
                  <button
                    onClick={() => handleBuyPlan('accelerator')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Get Started
                  </button>
                </div>
                <div className="text-center bg-lifetime py-5 px-4 mt-5 mg-negative-card-mob">
                  <h2 className="text-white mb-0">Freedom</h2>
                  <p className="text-white">Lifetime Access</p>
                  <h3 className="text-white">$1,497</h3>
                  <p className="text-white mb-lg-5">
                    One-time | Best Value
                    <span role="img" aria-label="Comet">
                      💫
                    </span>
                  </p>
                  <button
                    onClick={() => handleBuyPlan('freedom')}
                    type="button"
                    className="w-100 btn-get mt-4"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-relative">
              <div className="w-100 you-get d-lg-none">
                <h3>What you get</h3>
                <p>With each plan</p>
              </div>
            </div>
            <div className="col-12 overflow">
              <div className="min-w-table">
                <div className="bg-white d-flex justify-content-center prices">
                  <div className="d-flex w-100 w-lg-80 bg-title px-3 px-lg-0">
                    <div className="d-none d-lg-block w-19 position-relative">
                      <img src={saveDesktop} alt="Save" className="save" />
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Launchpad</h2>
                      <h3>$7</h3>
                      <p className="mb-5">One-time</p>
                      <button
                        onClick={() => handleBuyPlan('launchpad')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Growth</h2>
                      <h3>$97</h3>
                      <p className="mb-5">Monthly Subscription</p>
                      <button
                        onClick={() => handleBuyPlan('growth')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-24 text-center bg-lifetime px-2 px-lg-3 pt-4 py-sm-4 py-lg-5">
                      <h2 className="mt-3 mt-sm-0 mb-0">Freedom</h2>
                      <p className="">Lifetime Access</p>
                      <h3>$1,497</h3>
                      <p className="mb-lg-5">
                        One-time | Best Value
                        <span role="img" aria-label="Comet">
                          💫
                        </span>
                      </p>
                      <button
                        onClick={() => handleBuyPlan('freedom')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Accelerator</h2>
                      <h3>$897</h3>
                      <p className="mb-5">Pet year</p>
                      <button
                        onClick={() => handleBuyPlan('accelerator')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-negative">
                  <div className="w-100 w-lg-80 px-3 px-lg-0">
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        10+ Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Tools & Resources
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Audio Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Text Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Automation Tools*
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Full Content Library
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Digital Marketing
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Copywriting
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Sales Funnels
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Email Marketing
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        eCommerce
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        And Much More...
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center mt-4">
                      <div className="w-lg-19 d-none d-lg-flex align-items-center text-left pl-4 " />
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('launchpad')}
                          type="button"
                          className=" btn-get mb-5 mb-lg-0 "
                        >
                          Get Started <span className="d-lg-none">Now!</span>
                        </button>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('growth')}
                          type="button"
                          className=" btn-get mb-5 mb-lg-0 "
                        >
                          Get Started <span className="d-lg-none">Now!</span>
                        </button>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <button
                          onClick={() => handleBuyPlan('freedom')}
                          type="button"
                          className=" btn-get-gold mb-5 mb-lg-0"
                        >
                          Get Started <span className="d-lg-none">Now!</span>
                        </button>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('accelerator')}
                          type="button"
                          className=" btn-get mb-5 mb-lg-0"
                        >
                          Get Started <span className="d-lg-none">Now!</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mt-lg-5 pt-lg-5 position-relative pad-bottom">
              <img src={stars} alt="stars" className="stars" />
              <img src={star} alt="star" className="star1" />
              <img src={star} alt="star" className="star2" />
              <img
                src={stars}
                alt="stars"
                className="d-none d-lg-block stars-large"
              />
              <img
                src={star}
                alt="star"
                className="d-none d-lg-block star-large"
              />
              <img
                src={cubeLearn}
                alt="cube"
                className="cube-learn d-none d-lg-block"
              />
            </div>
          </div>
        </div>
      </Learn>

      <Global>
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 position-relative">
              <div className="bg-discover py-5 position-relative">
                <img
                  src={discover1}
                  alt="Person"
                  className="d-none d-md-block pe-none discover1 position-absolute"
                />
                <img
                  src={discover2}
                  alt="Person"
                  className="d-none d-md-block pe-none discover2 position-absolute"
                />
                <img
                  src={discover3}
                  alt="Person"
                  className="d-none d-md-block pe-none discover3 position-absolute"
                />
                <img
                  src={discover4}
                  alt="Person"
                  className="d-none d-md-block pe-none discover4 position-absolute"
                />
                <img
                  src={discover5}
                  alt="Person"
                  className="d-none d-md-block pe-none discover5 position-absolute"
                />
                <img
                  src={discover6}
                  alt="Person"
                  className="d-none d-md-block pe-none discover6 position-absolute"
                />

                <img
                  src={discoverMob1}
                  alt="Person"
                  className="d-md-none pe-none discover-mob1 position-absolute"
                />

                <img
                  src={discoverMob2}
                  alt="Person"
                  className="d-md-none pe-none discover-mob2 position-absolute"
                />

                <img
                  src={discoverMob3}
                  alt="Person"
                  className="d-md-none pe-none discover-mob3 position-absolute"
                />

                <img
                  src={discoverMob4}
                  alt="Person"
                  className="d-md-none pe-none discover-mob4 position-absolute"
                />

                <div className="row">
                  <div className="col-10 col-sm-8 col-xl-7 mx-auto text-center px-0 py-3">
                    <h3 className="mb-4 mb-sm-2">
                      LET’S BE WISERR <br className="d-md-none" />
                      TOGETHER
                    </h3>

                    <h2 className="mb-4 mb-sm-3">
                      Join a Thriving Global Community & Empower Your
                      Entrepreneurial Journey
                    </h2>

                    <p className="px-4 px-sm-0 mb-5 mb-lg-2">
                      Exchange ideas, discuss subjects, support peers, and boost
                      your career!
                    </p>

                    <button
                      onClick={() => handleBuyPlan('freedom')}
                      type="button"
                      className="btn-access my-4"
                    >
                      <span role="img" aria-label="flowers">
                        🎉
                      </span>{' '}
                      Get Early Access
                    </button>
                  </div>
                </div>
              </div>
              <img
                src={squareEnjoy}
                alt="Square"
                className="d-none d-lg-block square-global"
              />
            </div>
          </div>
        </div>
      </Global>

      <ModalBuyPlan
        show={showBuyPlan}
        planSelected={planSelected}
        onClose={() => setShowBuyPlan(false)}
      />
    </Container>
  );
};

export default Home;
