import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineChevronRight } from 'react-icons/md';
import { Form } from '@unform/web';
import { Modal } from './styles';

import logo from '~/assets/logo/logo-white-modal.svg';
import checkWhite from '~/assets/icons/check-white.svg';
import launchpad from '~/assets/defaults/launchpad.svg';
import growth from '~/assets/defaults/growth.svg';
import accelerator from '~/assets/defaults/accelerator.svg';
import freedom from '~/assets/defaults/freedom.svg';
import safe from '~/assets/defaults/safe-modal.png';
import money from '~/assets/defaults/money-modal.png';
import proteced from '~/assets/defaults/proteced.png';

import Input from '../Input';
import InputCheckbox from '../InputCheckbox';
import { formatPrice } from '~/utils/format';

interface IModal {
  show: boolean;
  planSelected: string;
  onClose: () => void;
}
const ModalNoThanks: React.FC<IModal> = ({ show, planSelected, onClose }) => {
  const [checked, setChecked] = useState(false);
  const [plan, setPlan] = useState('');
  const [othersPlans, setOthersPlans] = useState(false);
  const [isYourDetails, setIsYourDetails] = useState(true);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPlan(planSelected);
  }, [planSelected]);

  useEffect(() => {
    switch (plan) {
      case 'launchpad':
        setPrice(7);
        break;
      case 'growth':
        setPrice(97);
        break;
      case 'accelerator':
        setPrice(897);
        break;
      default:
        setPrice(1497);
        break;
    }
  }, [plan]);

  return (
    <Modal
      show={show}
      onHide={() => {
        onClose();
        setOthersPlans(false);
        setIsYourDetails(true);
      }}
      className="modal-plans"
      centered
      size="xl"
    >
      <Modal.Body className="bg-modal">
        <Form onSubmit={() => console.log('teste')}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-0">
                <div className="left-side position-relative mb-3 mb-sm-0">
                  <div
                    className={`${
                      othersPlans ? 'd-flex' : 'd-none'
                    } bg-change-plan  flex-column justify-content-center align-items-center`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setPlan('launchpad');
                        setOthersPlans(false);
                      }}
                      className={`${
                        plan === 'launchpad' ? 'current-plan' : ''
                      } d-flex align-items-center card-choose my-1`}
                    >
                      <img src={launchpad} alt="Launchpad" />

                      <div className="ml-3 ml-lg-4">
                        <h4 className="text-left mb-1 mb-lg-2">
                          Launchpad Plan
                        </h4>
                        <p className="text-left mb-0">$7 One-time</p>
                      </div>
                      {plan === 'launchpad' ? (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                          Current <br className="d-lg-none" />
                          Plan
                        </span>
                      ) : (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                          Choose <br className="d-lg-none" />
                          Plan
                        </span>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPlan('growth');
                        setOthersPlans(false);
                      }}
                      className={`${
                        plan === 'growth' ? 'current-plan' : ''
                      } d-flex align-items-center card-choose my-1`}
                    >
                      <img src={growth} alt="Growth" />

                      <div className="ml-3 ml-lg-4">
                        <h4 className="text-left mb-1 mb-lg-2">Growth Plan</h4>
                        <p className="text-left mb-0">$97 Monthly</p>
                      </div>

                      {plan === 'growth' ? (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                          Current <br className="d-lg-none" />
                          Plan
                        </span>
                      ) : (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                          Choose <br className="d-lg-none" />
                          Plan
                        </span>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPlan('accelerator');
                        setOthersPlans(false);
                      }}
                      className={`${
                        plan === 'accelerator' ? 'current-plan' : ''
                      } d-flex align-items-center card-choose my-1`}
                    >
                      <img src={accelerator} alt="Accelerator" />

                      <div className="ml-3 ml-lg-4">
                        <h4 className="text-left mb-1 mb-lg-2">
                          Accelerator Plan
                        </h4>
                        <p className="text-left mb-0">$897 Yearly</p>
                      </div>

                      {plan === 'accelerator' ? (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                          Current <br className="d-lg-none" />
                          Plan
                        </span>
                      ) : (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                          Choose <br className="d-lg-none" />
                          Plan
                        </span>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPlan('freedom');
                        setOthersPlans(false);
                      }}
                      className={`${
                        plan === 'freedom' ? 'current-plan' : ''
                      } d-flex align-items-center card-choose my-1`}
                    >
                      <img src={freedom} alt="Freedom" />

                      <div className="ml-3 ml-lg-4">
                        <h4 className="text-left mb-1 mb-lg-2">
                          Freedom Plan - Lifetime
                        </h4>
                        <p className="text-left mb-0">$1,497 One-time</p>
                      </div>

                      {plan === 'freedom' ? (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                          Current <br className="d-lg-none" />
                          Plan
                        </span>
                      ) : (
                        <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                          Choose <br className="d-lg-none" />
                          Plan
                        </span>
                      )}
                    </button>
                  </div>

                  <div className="bg d-flex justify-content-center align-items-center position-relative">
                    <img src={logo} alt="Wiserr" className="mb-5" />
                    <button
                      type="button"
                      onClick={() => setOthersPlans(true)}
                      className={`${
                        othersPlans ? 'opacity-0' : 'opacity-1'
                      } d-flex align-items-center card-change current-plan my-1`}
                    >
                      <img
                        src={
                          (plan === 'launchpad' ? launchpad : '') ||
                          (plan === 'growth' ? growth : '') ||
                          (plan === 'accelerator' ? accelerator : '') ||
                          (plan === 'freedom' ? freedom : '')
                        }
                        alt="Plan"
                      />

                      <div className="ml-3 ml-lg-4 ">
                        <h4 className="text-left mb-1 mb-lg-2">
                          {(plan === 'launchpad' ? 'Launchpad Plan' : '') ||
                            (plan === 'growth' ? 'Growth Plan' : '') ||
                            (plan === 'accelerator'
                              ? 'Accelerator Plan'
                              : '') ||
                            (plan === 'freedom'
                              ? 'Freedom Plan - Lifetime'
                              : '')}
                        </h4>
                        <p className="text-left mb-0">
                          {(plan === 'launchpad' ? '$7 One-time' : '') ||
                            (plan === 'growth' ? '$97 Monthly' : '') ||
                            (plan === 'accelerator' ? '$897 Yearly' : '') ||
                            (plan === 'freedom' ? '$1,497 One-time' : '')}
                        </p>
                      </div>

                      <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                        Change <br className="d-lg-none" />
                        Plan
                      </span>
                    </button>
                  </div>
                  <div className="px-4 px-lg-5 bg-left-bottom">
                    <h2 className="text-center">Simple pricing for everyone</h2>
                    <h3 className="text-center px-lg-5">
                      We design learning experiences that fit in your pocket and
                      help you grow your bottom line.
                    </h3>
                    <p className="d-flex align-items-center px-4 px-lg-5">
                      <img src={checkWhite} alt="Check" className="mr-4" />
                      Content curated for entrepreneurs
                    </p>
                    <p className="d-flex align-items-center px-4 px-lg-5">
                      <img src={checkWhite} alt="Check" className="mr-4" />
                      Trending topcis
                    </p>
                    <p className="d-flex align-items-center px-4 px-lg-5">
                      <img src={checkWhite} alt="Check" className="mr-4" />
                      Resources & Support
                    </p>
                    <p className="d-flex align-items-center mb-0 px-4 px-lg-5">
                      <img src={checkWhite} alt="Check" className="mr-4" />
                      7-Day money-back guarantee
                    </p>
                    <div className="text-center mt-5 pt-5 pb-4">
                      <Link to="/" className="mx-1 mx-lg-3">
                        Privacy Policy
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Terms of Service
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Security
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Cookies
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-0 order-lg-1">
                <div className="right-side">
                  <div className="mt-3 mb-4 d-flex">
                    <p className="ml-4 ml-lg-0 d-flex align-items-center mb-0">
                      <span
                        className={`${
                          isYourDetails ? 'text-black font-weight-bold' : ''
                        }`}
                      >
                        Your details
                      </span>
                      <span>
                        <MdOutlineChevronRight size={20} color="#000" />
                      </span>
                      <span
                        className={`${
                          !isYourDetails ? 'text-black font-weight-bold' : ''
                        }`}
                      >
                        Payment
                      </span>
                    </p>
                    <button
                      onClick={() => {
                        onClose();
                        setOthersPlans(false);
                        setIsYourDetails(true);
                      }}
                      type="button"
                      className="btn-back ml-auto mr-4 mr-lg-5"
                    >
                      Back
                    </button>
                  </div>
                  <div className="personal">
                    {isYourDetails ? (
                      <>
                        <h2>Welcome aboard!</h2>
                        <p className="mb-5">
                          Enter your information below to get started
                        </p>
                        <span className="d-block mb-3">Full Name</span>
                        <Input name="name" className="mb-4 input" />
                        <span className="d-block mb-3">Email</span>
                        <Input
                          name="email"
                          type="email"
                          className="mb-4 input"
                        />
                        <span className="d-block mb-3">Password</span>
                        <Input
                          name="password"
                          type="password"
                          className="mb-4 input"
                        />
                        <span className="d-block mb-3">
                          Who should we thank for recommending us?{' '}
                          <span className="font-weight-normal">(Optional)</span>
                        </span>
                        <Input name="recommend" className="mb-4 input" />
                        <InputCheckbox
                          type="checkbox"
                          name="terms"
                          className="justify-content-start"
                          options={[
                            {
                              labelHtml: true,
                              label:
                                'I have read and agree to the terms of service',
                              value:
                                'I have read and agree to the terms of service',
                              selected: checked,
                            },
                          ]}
                          onChange={() => setChecked(!checked)}
                        />
                        <button
                          type="button"
                          onClick={() => setIsYourDetails(false)}
                          className={`${
                            checked ? 'opacity-100' : 'pe-none opacity-50'
                          } btn-continue`}
                        >
                          Continue
                        </button>
                        <p className="login text-center mt-4">
                          Already have an account?{' '}
                          <a
                            className="font-weight-bold"
                            href="https://app.wiserr.io/sign-up"
                          >
                            Login
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <h2>Almost there</h2>
                        <p>Enter your payment details below</p>
                        <span className="d-block mb-3">Credit Card Number</span>
                        <Input
                          name="number"
                          type="number"
                          className="mb-4 input"
                        />
                        <div className="d-lg-flex">
                          <div className="pr-lg-3">
                            <span className="d-block mb-3">Expiration</span>
                            <Input
                              name="expiration"
                              type="number"
                              className="mb-4 input"
                            />
                          </div>
                          <div className="pl-lg-3">
                            <span className="d-block mb-3">CVC*</span>
                            <Input
                              name="cvc"
                              type="number"
                              className="mb-4 input"
                            />
                          </div>
                        </div>
                        <span className="d-block mb-3">Billing Address</span>
                        <Input name="address" className="mb-4 input mb-5" />

                        <p className="d-flex justify-content-between">
                          <span className="font-weight-normal">Subtotal</span>
                          <span className="font-weight-normal">
                            {formatPrice(price)}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between">
                          <span className="font-weight-normal">
                            Special Offer
                          </span>
                          <span className="font-weight-normal">-</span>
                        </p>
                        <hr />
                        <p className="total d-flex justify-content-between">
                          <span>Order Total:</span>
                          <span>{formatPrice(price)}</span>
                        </p>
                        <button type="button" className={` btn-continue`}>
                          Pay Now
                        </button>
                        <div className="d-flex my-4 imgs-footer">
                          <img src={safe} alt="Safe" />
                          <img src={money} alt="Money" />
                          <img src={proteced} alt="Proteced" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNoThanks;
